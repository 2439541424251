import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import {
  Hero,
  Tiles,
  Icons,
  Notice,
  Grid,
} from "page_components/clients-consulting"

const Developers = () => {
  const title = "Doradztwo podatkowe"

  return (
    <Layout
      seo={{
        title: title,
        description:
          "Dowiedz się, jak odliczyć podatek VAT od nieruchomości na wnajem. Sprawdź jak oszczędzić na kosztach podatkowych. ",
      }}
    >
      <Breadcrumbs
        title={title}
        parent={{
          title: "Dla klientów",
          path: "/dla-klientow/",
        }}
      />
      <PageHeader title={title} />
      <Hero />
      <Tiles />
      <Icons />
      <Notice />
      <Grid />
    </Layout>
  )
}

export default Developers
