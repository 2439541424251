/* eslint-disable react/jsx-no-target-blank */

import "./styles.scss"

import React from "react"
import { withPrefix } from "gatsby"

import LazyFadeImage from "components/LazyFadeImage"

import IcoDocs from "assets/icons/docs.svg"

const Grid = () => {
  return (
    <section className="clients-consulting-grid">
      <div className="container-fluid">
        <div className="row row--main align-items-center">
          <div className="col-xl-4 offset-xl-1 col-md-6">
            <h2>Jak działa VAT?</h2>
            <p>
              Rozpatrzmy zakup apartamentu o wartości{" "}
              <strong>615 000 zł</strong>. W cenie mieści się aż{" "}
              <strong>115.000 zł</strong> VAT (23% z <strong>500.000 zł</strong>
              ). Kwoty VAT w przypadku lokali użytkowych są wysokie – warto
              zatem zainteresować się możliwością ich odzyskania, zwłaszcza, że
              nie wymaga to wpisu do CEIDG.
            </p>
          </div>
          <div className="col-xl-6 offset-xl-1 col-md-6">
            <LazyFadeImage>
              <img
                className="clients-consulting-grid__img"
                src={
                  require("assets/images/clients/clients-consulting-grid-schema.svg")
                    .default
                }
                alt=""
              />
            </LazyFadeImage>
            <div className="clients-consulting-grid__info">
              <span>Przykład</span>
              <span>Sprzedaż lokalu za 500 000 zł netto (+115 000 zł VAT)</span>
            </div>
          </div>
        </div>

        <div className="row row--main align-items-center">
          <div className="col-xl-6 col-md-6">
            <LazyFadeImage>
              <div
                className="clients-consulting-grid__image"
                style={{
                  backgroundImage: `url('${
                    require("assets/images/clients/clients-consulting-grid-image.jpg")
                      .default
                  }')`,
                }}
              />
            </LazyFadeImage>
          </div>
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <h2>W jakim czasie następuje zwrot VAT?</h2>
            <div className="row">
              <div className="col-6">
                <div className="clients-consulting-grid__item">
                  <div>
                    <span>60</span>
                    <strong>dni</strong>
                  </div>
                  <h4>w terminie 60 dni od złożenia wniosku</h4>
                  <p>
                    jeżeli podatnik wykonał w okresie rozliczeniowym, którego
                    dotyczy zwrot, jakąkolwiek czynność opodatkowaną
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="clients-consulting-grid__item">
                  <div>
                    <span>180</span>
                    <strong>dni</strong>
                  </div>
                  <h4>w terminie 180 dni</h4>
                  <p>
                    gdy podatnik nie wykonał w okresie rozliczeniowym żadnej
                    czynności opodatkowanej.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row row--main align-items-center">
          <div className="col-md-6">
            <div className="clients-consulting-grid__cta">
              <a
                href={withPrefix("/kompendium-podatkowe.pdf")}
                target="_blank"
                className="button button--bordered"
              >
                <img src={IcoDocs} alt="" /> Pobierz poradnik o zwrot VAT
              </a>
            </div>
          </div>
          <div className="col-md-5 offset-md-1">
            <h4>
              O szczegóły zapytaj Twojego opiekuna Marshall Real Estate –
              przedstawi kolejne kroki i przeprowadzi przez cały proces
              przybliżając Cię do odzyskania VAT z zakupu wymarzonej
              nieruchomości.
            </h4>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Grid
