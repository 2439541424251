import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Icons = () => {
  const data = [
    {
      icon: require("assets/images/clients/clients-consulting-icons-icon-1.svg")
        .default,
      description:
        "posiadanie statusu zarejestrowanego, czynnego podatnika VAT",
    },
    {
      icon: require("assets/images/clients/clients-consulting-icons-icon-2.svg")
        .default,
      description: "prowadzenie wynajmu lokalu, w rozumieniu ustawy o VAT",
    },
    {
      icon: require("assets/images/clients/clients-consulting-icons-icon-3.svg")
        .default,
      description:
        "posiadanie dokumentów uprawniających do odliczenia podatku VAT wymienionych w art. 86 ust. 2 ustawy o podatku od towarów i usług (w tym faktury VAT za zakup lokalu)",
    },
  ]

  return (
    <section className="clients-consulting-icons">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <h2>
              Jakie warunki należy spełnić, aby móc odzyskać VAT nie prowadząc
              działalności gospodarczej?
            </h2>

            <div className="row justify-content-center">
              {data.map((item, index) => (
                <div className="col-lg-4 col-sm-6" key={index}>
                  <div className="clients-consulting-icons__item">
                    <div className="clients-consulting-icons__item-icon">
                      <LazyFadeImage>
                        <img src={item.icon} alt="" />
                      </LazyFadeImage>
                    </div>
                    <h4>{item.description}</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Icons
