import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Hero = () => {
  return (
    <section className="clients-consulting-hero">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-5 col-md-6">
            <h2>Zaoszczędź 23% - odzyskaj VAT</h2>
            <p>
              Nabywając apartament z myślą o jego wynajmie krótkoterminowym,
              mamy możliwość odliczenia pełnego podatku VAT. W przypadku lokali
              użytkowych wynosi on aż 23%. W celu odliczenia podatku nie jest
              konieczne prowadzenie zarejestrowanej działalności gospodarczej.
              Warunkiem jest jednak wykorzystanie zakupionej nieruchomości do
              wykonywania czynności opodatkowanych (np. wynajmu
              krótkoterminowego).
            </p>
          </div>
          <div className="col-xl-6 offset-xl-1 col-md-6">
            <LazyFadeImage>
              <div
                className="clients-consulting-hero__image"
                style={{
                  backgroundImage: `url('${
                    require("assets/images/clients/clients-consulting-hero-image.jpg")
                      .default
                  }')`,
                }}
              />
            </LazyFadeImage>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
