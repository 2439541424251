import "./styles.scss"

import React from "react"

const Tiles = () => {
  return (
    <section className="clients-consulting-tiles">
      <div className="container-fluid">
        <h2>Kto jest uprawniony do odzyskania VAT?</h2>
        <div className="row">
          <div className="col-xl-6">
            <div className="clients-consulting-tiles__item">
              <h3>Osoby prowadzące działalność gospodarczą</h3>
              <p>
                Warunkiem odliczenia podatku VAT z tytułu wydatków poniesionych
                na zakup apartamentu jest posiadanie statusu czynnego podatnika
                VAT. Odliczenie podatku VAT w praktyce oznacza pomniejszenie
                podatku należnego VAT za miesiąc, w którym wystawiono fakturę
                zakupu lokalu. Jeśli osoby prowadzące działalność gospodarczą do
                tej pory korzystały ze zwolnienia od opodatkowania VAT,
                konieczne jest złożenie formularza VAT-R przed początkiem
                miesiąca, w którym nastąpi rezygnacja ze zwolnienia. Formularz
                powinien zostać złożony w miesiącu poprzedzającym wpłatę
                pierwszej transzy. Należy jednak pamiętać, że rezygnacja ze
                zwolnienia oznacza konieczność naliczania podatku VAT od całej
                sprzedaży, dokonywanej w ramach działalności gospodarczej.
              </p>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="clients-consulting-tiles__item">
              <h3>Osoby nie prowadzące działalności gospodarczej</h3>
              <p>
                Osoby fizyczne powinny niezwłocznie zarejestrować się w
                odpowiednim urzędzie skarbowym w celu uzyskania statusu czynnego
                podatnika VAT. Należy więc wypełnić formularz VAT-R. Zgłoszenie
                powinno zostać dokonane przed otrzymaniem pierwszej faktury bądź
                podpisaniem umowy notarialnej. Rejestracja jako podatnik VAT nie
                jest równoznaczne z podjęciem działalności gospodarczej.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Tiles
