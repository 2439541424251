import "./styles.scss"

import React from "react"

const Notice = () => {
  return (
    <section className="clients-consulting-notice">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="clients-consulting-notice__wrapper">
              <img src={require("assets/icons/notice.svg").default} alt="" />
              <div>
                <h3>Ważne!</h3>
                <p>
                  Zwrot podatku VAT jest możliwy w przypadku wykorzystywania
                  apartamentu do wykonywania czynności opodatkowanych (np.
                  wynajmu dla turystów), z wyłączeniem użytku prywatnego.
                  Używanie nieruchomości do celów prywatnych nie pozwala na
                  zwrot całości naliczonego podatku. Z tego też względu, nie
                  jest możliwy zwrot podatku w przypadku zakupu lokalu objętego
                  8% stawką VAT do celów mieszkaniowych.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Notice
